<template>
  <div class="flex flex-col flex-1">


    <portal-target name="popup-request-more-information-revellation"></portal-target>
    <Header />

    <ValidationObserver
      class="flex flex-1"
      v-slot="{ invalid }"
      v-if="!revelation_is_loading"
    >
      <router-view :form="form" :cadastre="cadastre" :invalid="invalid">
      </router-view>
    </ValidationObserver>
  </div>
</template>

<script>
import Header from './components/Header.vue'
export default {
  components: {
    Header,
  },
  data() {
    return {
      form: {
        civilite: null,
        nom: null,
        prenom: null,
        email: null,
        tel: null,
        informations: {
          other_civilite: null,
          refsCadastrale: [],
          current_parcelle: null,
          parcelles_voisines: [],
          batiments: [],
          coords_parcelle_principale: [],
          adresse: null,
          residence: null,
          superficie_totale: null,
          superficie_construite: null,
          pieces: null,
          type_bien: null,
          other_type_bien: null,
          etat_bien: null,
          other_etat_bien: null,
          label_profile: null,
          other_label_profile: null,
          on_sale: null,
          is_estimated: null,
          more_informations: null,
          code_insee: null,
          code_postal: null,
          departement: null,
          region: null,
          zonage_plu: [],
          is_contact_with_promoteur: null,
          estimated_price: null,
          distance_projet_promoteur_autour: null,
          autre_bien_constructible: null,
          has_other_bien: null,
          has_bien_constructible: null,
          has_neighbor_or_otherOwner: null,
          neighbors_or_otherOwners: [
            {
              nom: null,
              prenom: null,
              adresse: {
                label: null,
                long: null,
                lat: null,
                code_insee: null,
              },
            },
          ],
        },
        g_p_adresse_id: null,
      },
      cadastre: {
        old_adresse: null,
        is_api_loading: false,
        data: {},
        is_parcelle_changing: false,
        is_parcelle_aggregating: false,
        parcelle_autour: [],
        features_autour: [],
        error_parcelle_not_found: null,
        is_adresse_changed: false,
        is_loaded_cross_gu: false,
        is_loaded_cross_revelation: false,
      },
      revelation_is_loading: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },
  created() {
     this.$store.commit('map/REMOVE_MAP')
    if (this.$route.params.id_revelation) {
      this.revelation_is_loading = true
      this.$http
        .get(
          `/grand-publics/reveler-potentiels/${this.$route.params.id_revelation}`,
        )
        .then((response) => {
          this.form = _.merge(this.form, response.data.revelerPotentiel)
          this.cadastre.is_loaded_cross_revelation = true
          this.revelation_is_loading = false
        })
    }
    if (
      this.$route.name != 'revelationStep1' &&
      !this.form.informations.adresse
    ) {
      let params = this.$route.query.from_europe1
        ? {
            adresse: this.$route.query.adresse,
            email: this.$route.query.email,
            tel: this.$route.query.tel,
            from_europe1: this.$route.query.from_europe1,
          }
        : {}
      this.$router.push({
        name: 'revelationStep1',
        params: {
          id_revelation: this.$route.params.id_revelation ?? null,
        },
        query: { ...params },
      })
    }
    this.form.informations.adresse = this.$route.query.adresse
      ? this.$route.query.adresse
      : null
    this.prefillStepSeven()
  },
  methods: {
    prefillStepSeven() {
      this.form.nom = this.user.nom
      this.form.prenom = this.user.prenom

      this.form.email =
        this.user.email && this.user.email !== undefined
          ? this.user.email
          : this.$route.query.email

      this.form.tel =
        this.user.telephone_mobile && this.user.telephone_mobile !== undefined
          ? this.user.telephone_mobile
          : this.$route.query.tel

      this.form.civilite = this.user.identifiant_client
    },
  },
  beforeDestroy() {
    this.$store.commit('Tunnel/SET_RESET_TUNNEL_STORE')
    this.$store.commit('address/ResetStoreGUAddress')
  },
}
</script>
